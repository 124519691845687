import useSWR from "swr";
import { BasicDataPayload } from "../../pages/Home";
import fetchWithToken from "../fetcher";

const useBasicData = () => {
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const accessToken = sessionStorage.getItem("minderfinder-access-token");

  const {
    data: basicDataPayload,
    error: basicDataError,
    isLoading: basicDataIsLoading,
    mutate: basicDataMutate,
  } = useSWR<BasicDataPayload>(
    [`${REACT_APP_API_BASE_URL}/basicUserData`, accessToken],
    ([url, token]) => fetchWithToken(url, token as string)
    // { refreshInterval: 1000 }
  );

  return {
    basicDataPayload,
    basicDataError,
    basicDataIsLoading,
    basicDataMutate,
  };
};

export default useBasicData;
