import useSWR from "swr";
import { EmployeesPayload } from "../../components/ManageEmployees";
import fetchWithToken from "../fetcher";

const useEmployees = () => {
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const accessToken = sessionStorage.getItem("minderfinder-access-token");

  const {
    data: employeesPayload,
    error: employeesError,
    isLoading: employeesIsLoading,
    mutate: employeesMutate,
  } = useSWR<EmployeesPayload>(
    [`${REACT_APP_API_BASE_URL}/employees`, accessToken],
    ([url, token]) => fetchWithToken(url, token as string)
    // { refreshInterval: 1000 }
  );

  return {
    employeesPayload,
    employeesError,
    employeesIsLoading,
    employeesMutate,
  };
};

export default useEmployees;
