import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const Success = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth0();
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const accessToken = sessionStorage.getItem("minderfinder-access-token");
  const stripeCheckoutSessionId = sessionStorage.getItem(
    "stripeCheckoutSessionId"
  );

  const finalizeTopUp = async () => {
    if (user && isAuthenticated) {
      const response = await axios.put(
        `${REACT_APP_API_BASE_URL}/finalizeTopUp`,
        { stripeCheckoutSessionId },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      if (response.data.status === "ok") {
        navigate("/");
      }
    }
  };

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("stripeCheckoutSessionId");
    };
  }, []);

  useEffect(() => {
    finalizeTopUp();
  }, [user]);

  return (
    <div>
      <h1>Payment Successful</h1>
      <h1>Navigating back to your home page</h1>
    </div>
  );
};

export default Success;
