import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import EmployeeListItem from "./EmployeeListItem";
import Button from "./Button";
import sortEmployeesArray from "../lib/helpers/sortEmployeesArray";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDownAZ,
  faArrowDownZA,
} from "@fortawesome/free-solid-svg-icons";
import useEmployees from "../lib/hooks/useEmployees.hook";
import useBasicData from "../lib/hooks/useBasicData.hook";
import { MutatorOptions } from "swr";
import axios from "axios";

export interface EmployeeDetails {
  firstName: string;
  lastName: string;
  code: string;
  _id: string;
}

export interface Employee {
  monthlyCredits: number;
  _employee: EmployeeDetails;
}

export interface EmployeesPayload {
  status: "ok" | "error";
  code: number;
  organizationId: string;
  employees: Employee[];
}

const ManageEmployees = () => {
  const {
    employeesPayload,
    employeesError,
    employeesIsLoading,
    employeesMutate,
  } = useEmployees();

  const { basicDataPayload } = useBasicData();

  const userType = basicDataPayload?.userType;

  const [updatedMonthlyCredits, setUpdatedMonthlyCredits] = useState<
    Record<string, number>
  >({});

  const [isAlphabetical, setIsAlphabetical] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const accessToken = sessionStorage.getItem("minderfinder-access-token");

  const initialMonthlyCredits = employeesPayload?.employees
    ? employeesPayload.employees.reduce(
        (
          accumulatorObj: Record<string, number>,
          currentEmployee: Employee
        ) => ({
          ...accumulatorObj,
          [currentEmployee._employee._id]: currentEmployee.monthlyCredits,
        }),
        {}
      )
    : {};

  const areChangesMade = initialMonthlyCredits
    ? Object.entries(updatedMonthlyCredits).some(
        ([key, value]) => initialMonthlyCredits[key] !== value
      )
    : false;

  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const BUTTON_VALUE = 10;
  const MAX_EMPLOYEE_MONTHLY_CREDITS = 1000;

  const sortingOrderMap = {
    alphabetical: { lastNameOrder: 1, firstNameOrder: 1, codeOrder: 1 },
    reverse: { lastNameOrder: -1, firstNameOrder: -1, codeOrder: -1 },
  };

  const filteredSortedEmployees = employeesPayload?.employees
    ? sortEmployeesArray(
        employeesPayload.employees,
        isAlphabetical
          ? sortingOrderMap["alphabetical"]
          : sortingOrderMap["reverse"]
      ).filter((e) =>
        `${e._employee.firstName} ${e._employee.lastName} ${e._employee.code}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    : [];

  const handleToggleAlphabetical = () => {
    setIsAlphabetical((prev) => !prev);
  };

  const handleChangeSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleIncrementCredits = (employeeId: string, value: number) => {
    if (value < 0) {
      return;
    }
    setUpdatedMonthlyCredits((prev) => ({
      ...prev,
      [employeeId]:
        value <= MAX_EMPLOYEE_MONTHLY_CREDITS
          ? value
          : MAX_EMPLOYEE_MONTHLY_CREDITS,
    }));
  };

  const handleChangeCredits = (
    e: React.ChangeEvent<HTMLInputElement>,
    employeeId: string
  ) => {
    const value = e.target.value;
    const cleanedValue = value.replaceAll(/[^0-9]/g, "");
    if (Number(cleanedValue) <= MAX_EMPLOYEE_MONTHLY_CREDITS) {
      setUpdatedMonthlyCredits((prev) => ({
        ...prev,
        [employeeId]: Number(cleanedValue),
      }));
    }
  };

  const handleResetAllCredits = () => {
    setUpdatedMonthlyCredits(initialMonthlyCredits);
  };

  const handleSaveChangesUpdateFn = async (
    updatedData: EmployeesPayload,
    changes: Record<string, number>
  ) => {
    try {
      if (employeesPayload?.organizationId) {
        const response = await axios.put(
          `${REACT_APP_API_BASE_URL}/updateMonthlyCredits`,
          { organizationId: employeesPayload.organizationId, changes },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );

        if (response.data.status === "ok") {
        }
      }
    } catch (err) {}
    return updatedData;
  };

  const handleSaveCreditUpdates = async () => {
    const changes = Object.fromEntries(
      Object.entries(updatedMonthlyCredits).filter(
        ([key, value]) => initialMonthlyCredits[key] !== value
      )
    );
    if (employeesPayload?.organizationId && Object.keys(changes).length > 0) {
      const updatedData: EmployeesPayload =
        !employeesPayload || !employeesPayload.employees
          ? {
              status: "ok",
              code: 0,
              organizationId: "",
              employees: [],
            }
          : {
              ...employeesPayload,
              employees: employeesPayload.employees.map((e: Employee) => ({
                ...e,
                monthlyCredits: updatedMonthlyCredits[e._employee._id],
              })),
            };

      const options: MutatorOptions<EmployeesPayload> = {
        populateCache: false,
        rollbackOnError: true,
        revalidate: true,
        optimisticData: updatedData,
      };

      employeesMutate(handleSaveChangesUpdateFn(updatedData, changes), options);
    }
  };

  useEffect(() => {
    setUpdatedMonthlyCredits(initialMonthlyCredits);
  }, [employeesPayload?.employees]);

  if (userType === "employee") {
    return <Navigate to="/" />;
  }

  return (
    <>
      {Object.keys(updatedMonthlyCredits).length > 0 ? (
        <>
          <div className="w-full h-20 fixed top-20 pb-3 border-b border-black flex justify-start items-end bg-white z-10">
            <FontAwesomeIcon
              icon={isAlphabetical ? faArrowDownAZ : faArrowDownZA}
              className="ml-10 text-3xl cursor-pointer"
              onClick={handleToggleAlphabetical}
            />
            <input
              className="ml-4 pl-2 py-0.5 border rounded-md text-sm"
              type="text"
              placeholder="Search employees"
              value={searchTerm}
              onChange={handleChangeSearchTerm}
            />
          </div>
          <div className="mt-20">
            {filteredSortedEmployees.length > 0 ? (
              filteredSortedEmployees.map((e, index) => (
                <EmployeeListItem
                  key={`employee-list-item-${index}`}
                  id={e._employee._id}
                  firstName={e._employee.firstName}
                  lastName={e._employee.lastName}
                  code={e._employee.code}
                  monthlyCredits={updatedMonthlyCredits[e._employee._id]}
                  buttonValue={BUTTON_VALUE}
                  onIncrementCredits={handleIncrementCredits}
                  onChangeCredits={handleChangeCredits}
                  edited={
                    updatedMonthlyCredits[e._employee._id] !==
                    initialMonthlyCredits[e._employee._id]
                  }
                />
              ))
            ) : (
              <div className="w-full h-16 bg-white flex justify-center items-center border-b">
                <h1>No employees found</h1>
              </div>
            )}
          </div>
          <div className="mt-4">
            <Button
              text="Cancel"
              onClick={areChangesMade ? handleResetAllCredits : () => {}}
              isEnabled={areChangesMade}
              additionalStyles={`mx-2 ${
                areChangesMade ? "bg-red-400 hover:bg-red-500" : ""
              }`}
            />
            <Button
              text="Save"
              onClick={areChangesMade ? handleSaveCreditUpdates : () => {}}
              isEnabled={areChangesMade}
              additionalStyles={`mx-2 ${
                areChangesMade ? "bg-emerald-400 hover:bg-emerald-500" : ""
              }`}
            />
          </div>
        </>
      ) : (
        <div className="w-full h-16 mt-6 bg-white flex justify-center items-center border-b">
          <h1>No employees found</h1>
        </div>
      )}
    </>
  );
};

export default ManageEmployees;
