import { useAuth0 } from "@auth0/auth0-react";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AccountHeader = (props: { picture: string }) => {
  const { logout } = useAuth0();
  return (
    <div className="w-full h-20 bg-white fixed top-0 z-20 drop-shadow-md flex flex-col justify-center items-end">
      <div className="flex flex-col items-center mr-10">
        <FontAwesomeIcon
          icon={faRightFromBracket}
          className="absolute w-5 h-5 right-4 top-2 cursor-pointer"
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
        />
        <img className="w-12 h-12 rounded-full" src={props.picture} />
      </div>
    </div>
  );
};

export default AccountHeader;
