import { useState, useEffect } from "react";
import axios from "axios";
import { Employee } from "./ManageEmployees";
import OrganizationDashboard from "./OrganizationDashboard";
import TopUpPage from "./TopUpPage";
import { ContactInfo } from "./EmployeeProfile";
import useOrganization from "../lib/hooks/useOrganization.hook";

export interface Organization {
  organizationId: string;
  name: string;
  email: string;
  code: string;
  organizationName: string;
  remainingCredits: number;
  billingDay: number;
  sendDay: number;
  employees: Employee[];
  contactInfo: ContactInfo;
}

export interface OrganizationPayload {
  status: "ok" | "error";
  code: number;
  organization: Organization;
}

// TODO: fetch transactions to check if monthly credits have been sent yet,
// display past date if credits not yet received

const OrganizationProfile = () => {
  const {
    organizationPayload,
    organizationError,
    organizationIsLoading,
    organizationMutate,
  } = useOrganization();

  const [data, setData] = useState<Organization>();

  const [screenToDisplay, setScreenToDisplay] = useState<"default" | "topUp">(
    "default"
  );

  const [topUpOptions, setTopUpOptions] = useState<number[]>([100, 200, 300]);
  const [topUpValue, setTopUpValue] = useState<number>(0);
  const [topUpValueIndex, setTopUpValueIndex] = useState<number>(-1);
  const [customValue, setCustomValue] = useState<number>(0);

  const accessToken = sessionStorage.getItem("minderfinder-access-token");

  const totalMonthlyEmployeeCredits = data?.employees.reduce(
    (total, current) => total + current.monthlyCredits,
    0
  );

  const updateTopUpOptions = () => {
    const deficit =
      (totalMonthlyEmployeeCredits as number) -
      (data?.remainingCredits as number);
    const options =
      deficit > 0 ? [deficit, deficit * 2, deficit * 3] : [100, 200, 300];
    setTopUpOptions(options);
  };

  const MAX_CUSTOM_VALUE = 1000;
  const CREDIT_TOP_UP_CURRENCY = "gbp";
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleResetTopUpVariables = () => {
    setTopUpValue(0);
    setTopUpValueIndex(-1);
    setCustomValue(0);
  };

  const handleClickTopUpValue = (value: number | string, index: number) => {
    const num = Number(value);
    setTopUpValueIndex((prevIndex) => {
      if (prevIndex === index) {
        setTopUpValue(0);
        return -1;
      } else {
        setTopUpValue(num);
        return index;
      }
    });
  };

  const handleChangeCustomValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const cleanedValue = value.replaceAll(/[^0-9]/g, "");
    if (Number(cleanedValue) <= MAX_CUSTOM_VALUE) {
      setCustomValue(Number(cleanedValue));
      setTopUpValue(Number(cleanedValue));
    }
  };

  const handleSubmitTopUp = async () => {
    const response = await axios.post(
      `${REACT_APP_API_BASE_URL}/initiateTopUp`,
      {
        amount: customValue,
        currency: CREDIT_TOP_UP_CURRENCY,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    if (response.data.url) {
      sessionStorage.setItem("stripeCheckoutSessionId", response.data.id);
      window.location = response.data.url;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    sessionStorage.removeItem("stripeCheckoutSessionId");
    return () => {
      handleResetTopUpVariables();
      window.scrollTo(0, 0);
    };
  }, []);

  useEffect(() => {
    updateTopUpOptions();
  }, [data]);

  return (
    <>
      {organizationPayload && screenToDisplay === "default" ? (
        <OrganizationDashboard
          onClickAddCredits={() => setScreenToDisplay("topUp")}
        />
      ) : (
        <TopUpPage
          customValue={customValue}
          maxCustomValue={MAX_CUSTOM_VALUE}
          onChange={handleChangeCustomValue}
          onSubmit={topUpValue > 0 ? handleSubmitTopUp : () => {}}
          isSubmitEnabled={topUpValue > 0}
          onClickBack={() => setScreenToDisplay("default")}
        />
      )}
    </>
  );
};

export default OrganizationProfile;
