import { Link, Navigate, Outlet, useLocation } from "react-router-dom";
import useBasicData from "../lib/hooks/useBasicData.hook";

const ChildcareProviders = () => {
  const location = useLocation();

  const { basicDataPayload } = useBasicData();

  const userType = basicDataPayload?.userType;

  if (userType === "organization") {
    return <Navigate to="/" />;
  }

  return (
    <div className="w-full">
      <Outlet />

      {location.pathname === "/providers" && (
        <>
          <Link
            className="w-full h-16 flex justify-center items-center border"
            to="provider1"
          >
            <h1>Provider 1</h1>
          </Link>
          <Link
            className="w-full h-16 flex justify-center items-center border"
            to="provider2"
          >
            <h1>Provider 2</h1>
          </Link>
        </>
      )}
    </div>
  );
};

export default ChildcareProviders;
