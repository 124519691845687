import { Employee } from "../../components/ManageEmployees";

const sortEmployeesArray = (
  arr: Employee[],
  sortingOrder: {
    lastNameOrder: number;
    firstNameOrder: number;
    codeOrder: number;
  }
) => {
  if (
    Math.abs(sortingOrder.lastNameOrder) > 1 ||
    Math.abs(sortingOrder.firstNameOrder) > 1 ||
    Math.abs(sortingOrder.codeOrder) > 1
  ) {
    return arr;
  }

  const sortedArray = arr.sort((a, b) => {
    const lastNameA = a._employee.lastName.toLowerCase();
    const lastNameB = b._employee.lastName.toLowerCase();
    const firstNameA = a._employee.firstName.toLowerCase();
    const firstNameB = b._employee.firstName.toLowerCase();
    const codeA = a._employee.code.toLowerCase();
    const codeB = b._employee.code.toLowerCase();

    return lastNameA.localeCompare(lastNameB) !== 0
      ? sortingOrder.lastNameOrder * lastNameA.localeCompare(lastNameB)
      : firstNameA.localeCompare(firstNameB) !== 0
      ? sortingOrder.firstNameOrder * firstNameA.localeCompare(firstNameB)
      : sortingOrder.codeOrder * codeA.localeCompare(codeB);
  });

  return sortedArray;
};

export default sortEmployeesArray;
