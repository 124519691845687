import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { Outlet, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import AccountHeader from "../components/AccountHeader";
import AccountNavBar from "../components/AccountNavBar";
import useBasicData from "../lib/hooks/useBasicData.hook";

export interface BasicDataPayload {
  status: "ok" | "error";
  code: number;
  name: string;
  userType: "employee" | "organization";
  userCode: string;
  message?: string;
}

const Home = () => {
  const navigate = useNavigate();

  const {
    basicDataPayload,
    basicDataError,
    basicDataIsLoading,
    basicDataMutate,
  } = useBasicData();

  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  const [accessToken, setAccessToken] = useState<string>("");
  const [data, setData] = useState<{
    name: string;
    userType: "employee" | "organization";
    userCode: string;
  }>();
  const [isNavBarExpanded, setIsNavBarExpanded] = useState<boolean>(false);

  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const fetchAccessToken = async () => {
    if (user) {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
      sessionStorage.setItem("minderfinder-access-token", token);
    } else {
    }
  };

  const handleShowNavBar = () => {
    setIsNavBarExpanded(true);
  };

  const handleHideNavBar = () => {
    setIsNavBarExpanded(false);
  };

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("minderfinder-access-token");
    };
  }, []);

  useEffect(() => {
    fetchAccessToken();

    if (isLoading || basicDataIsLoading) {
      return;
    }

    if (
      user &&
      (!basicDataPayload || basicDataPayload.message === "User not found")
    ) {
      navigate("/register");
    } else if (
      !user ||
      (basicDataPayload &&
        !(basicDataPayload.userType && basicDataPayload.userCode))
    ) {
      navigate("/login");
    }
  }, [user, isLoading, accessToken, basicDataIsLoading]);

  if (isLoading || basicDataIsLoading) {
    return (
      <div className="mt-16">
        <h1>Loading profile</h1>
        <FontAwesomeIcon icon={faSpinner} spin />
      </div>
    );
  }

  return (
    <div className="h-screen">
      {user && isAuthenticated && basicDataPayload && (
        <div className="h-full flex items-center">
          <AccountHeader picture={user.picture as string} />
          <AccountNavBar
            userType={basicDataPayload.userType}
            picture={user.picture as string}
            isExpanded={isNavBarExpanded}
            onShow={handleShowNavBar}
            onHide={handleHideNavBar}
          />
          <div className="h-full w-full sm:min-w-[660px] sm:pl-[200px] pt-20 flex flex-col">
            <Outlet />
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
