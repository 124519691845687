import { useState, useEffect } from "react";
import axios from "axios";
import findNextDate from "../lib/findNextDate";
import { ContactInfo, User } from "./EmployeeProfile";
import useProfile from "../lib/hooks/useProfile.hook";

// TODO: fetch transactions to check if monthly credits have been sent yet,
// display past date if credits not yet received

const EmployeeDashboard = (props: {
  onClickAddCredits: () => void;
  onSubmitUpdatedContactInfo: (updatedContactInfo: ContactInfo) => void;
}) => {
  const { profilePayload, profileError, profileIsLoading, profileMutate } =
    useProfile();

  const blankContactInfo = {
    address1: "",
    address2: "",
    city: "",
    postcode: "",
    country: "",
    phone: "",
  };

  const [isContactInfoEditable, setIsContactInfoEditable] =
    useState<boolean>(false);
  const [updatedContactInfo, setUpdatedContactInfo] = useState<ContactInfo>(
    profilePayload ? profilePayload.user.contactInfo : blankContactInfo
  );

  const accessToken = sessionStorage.getItem("minderfinder-access-token");

  const areChangesMade =
    profilePayload !== undefined &&
    Object.keys(profilePayload.user.contactInfo).some((key: any) => {
      return (
        profilePayload.user.contactInfo[key as keyof ContactInfo] !==
        updatedContactInfo[key as keyof ContactInfo]
      );
    });

  const handleChangeContactInfo = (key: string, value: string) => {
    const cleanedValue = value.slice(0, 1) === " " ? value.slice(1) : value;
    setUpdatedContactInfo((prev) => ({ ...prev, [key]: cleanedValue }));
  };

  const handleCancelUpdateContactInfo = () => {
    if (profilePayload) {
      setUpdatedContactInfo(profilePayload.user.contactInfo);
    }
  };

  useEffect(() => {
    setIsContactInfoEditable(areChangesMade);
  }, [profilePayload?.user]);

  return (
    <>
      {profilePayload && (
        <div className="ml-8 pb-10">
          <h1 className="pt-11 text-left text-3xl">Account</h1>
          <div className="my-4 px-4 py-3 w-fit border rounded-md">
            <div className="mb-3 flex justify-between">
              <p className="text-left text-lg font-semibold">Balance</p>
              <p className="text-left text-lg font-semibold">
                {`£${profilePayload.user.remainingCredits}`}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-left text-xs">Monthly company contribution</p>
              <p className="text-left text-xs">
                £{profilePayload.user.monthlyCredits}
              </p>
            </div>
            <div className="mb-4 flex justify-between">
              <p className="text-left text-xs">Your most recent top-up</p>
              <p className="text-left text-xs">
                {profilePayload.user.topUps.length > 0
                  ? `£${profilePayload.user.topUps[0].quantity}`
                  : "N/A"}
              </p>
            </div>
            <p className="text-left text-xs mt-1">
              Next credit renewal on{" "}
              {findNextDate(profilePayload.user.sendDay, "dddd, MMM D")}
            </p>
          </div>
          <div
            className="w-fit mt-6 px-8 py-2 rounded-2xl bg-black text-white flex justify-center items-center cursor-pointer"
            onClick={props.onClickAddCredits}
          >
            <p className="font-semibold text-xs">Add credits</p>
          </div>
          <h2 className="mt-20 text-left text-2xl">Personal Information</h2>
          <div className="mt-2 mb-4 flex flex-wrap">
            <div className="w-fit min-w-[200px] pr-4 mr-1 my-2">
              <p className="text-sm text-left text-gray-500">First name</p>
              <p className="text-sm text-left px-2 border rounded-sm">
                {profilePayload.user.firstName}
              </p>
            </div>
            <div className="w-fit min-w-[200px] pr-4 mr-1 my-2">
              <p className="text-sm text-left text-gray-500">Last name</p>
              <p className="text-sm text-left px-2 border rounded-sm">
                {profilePayload.user.lastName}
              </p>
            </div>
            <div className="w-fit min-w-[200px] pr-4 mr-1 my-2">
              <p className="text-sm text-left text-gray-500">User code</p>
              <p className="text-sm text-left px-2 border rounded-sm">
                {profilePayload.user.code}
              </p>
            </div>
            <div className="w-fit min-w-[200px] pr-4 mr-1 my-2">
              <p className="text-sm text-left text-gray-500">E-mail</p>
              <p className="text-sm text-left px-2 border rounded-sm">
                {profilePayload.user.email}
              </p>
            </div>
            <div className="w-fit min-w-[200px] pr-4 mr-1 my-2">
              <p className="text-sm text-left text-gray-500">Organization</p>
              <p className="text-sm text-left px-2 border rounded-sm">
                {profilePayload.user.organizationName}
              </p>
            </div>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (areChangesMade) {
                props.onSubmitUpdatedContactInfo(updatedContactInfo);
              }
            }}
          >
            <h3 className="mt-10 text-left text-lg">Contact Information</h3>
            <div className="w-[90%] mt-2 mb-4 flex flex-wrap">
              <div className="w-full min-w-[200px] pr-4 mr-1 my-2">
                <p className="text-sm text-left text-gray-500">
                  Address line 1
                </p>
                <input
                  className="w-full text-sm text-left px-2 border rounded-sm"
                  value={updatedContactInfo.address1}
                  required
                  disabled={!isContactInfoEditable}
                  onChange={(e) =>
                    handleChangeContactInfo("address1", e.target.value)
                  }
                />
              </div>
              <div className="w-full min-w-[200px] pr-4 mr-1 my-2">
                <p className="text-sm text-left text-gray-500">
                  Address line 2
                </p>
                <input
                  className="w-full text-sm text-left px-2 border rounded-sm"
                  value={updatedContactInfo.address2}
                  disabled={!isContactInfoEditable}
                  onChange={(e) =>
                    handleChangeContactInfo("address2", e.target.value)
                  }
                />
              </div>
              <div className="w-1/3 min-w-[200px] pr-4 mr-1 my-2">
                <p className="text-sm text-left text-gray-500">City</p>
                <input
                  className="w-full text-sm text-left px-2 border rounded-sm"
                  value={updatedContactInfo.city}
                  required
                  disabled={!isContactInfoEditable}
                  onChange={(e) =>
                    handleChangeContactInfo("city", e.target.value)
                  }
                />
              </div>
              <div className="w-1/3 min-w-[200px] pr-4 mr-1 my-2">
                <p className="text-sm text-left text-gray-500">
                  Postal code / Zip
                </p>
                <input
                  className="w-full text-sm text-left px-2 border rounded-sm"
                  value={updatedContactInfo.postcode}
                  required
                  disabled={!isContactInfoEditable}
                  onChange={(e) =>
                    handleChangeContactInfo("postcode", e.target.value)
                  }
                />
              </div>
              <div className="w-full">
                <div className="w-1/3 min-w-[200px] pr-4 mr-1 my-2">
                  <p className="text-sm text-left text-gray-500">Country</p>
                  <input
                    className="w-full text-sm text-left px-2 border rounded-sm"
                    value={updatedContactInfo.country}
                    required
                    disabled={!isContactInfoEditable}
                    onChange={(e) =>
                      handleChangeContactInfo("country", e.target.value)
                    }
                  />
                </div>
                <div className="w-1/3 min-w-[200px] pr-4 mr-1 my-4">
                  <p className="text-sm text-left text-gray-500">
                    Phone number
                  </p>
                  <input
                    className="w-full text-sm text-left px-2 border rounded-sm"
                    value={updatedContactInfo.phone}
                    required
                    disabled={!isContactInfoEditable}
                    onChange={(e) =>
                      handleChangeContactInfo("phone", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div
                className="w-[100px] px-8 py-2 mr-4 rounded-2xl bg-black text-white flex justify-center items-center cursor-pointer"
                onClick={
                  isContactInfoEditable
                    ? () => {
                        handleCancelUpdateContactInfo();
                        setIsContactInfoEditable(false);
                      }
                    : () => {
                        setIsContactInfoEditable(true);
                      }
                }
              >
                <p className="font-semibold text-xs">
                  {isContactInfoEditable ? "Cancel" : "Edit"}
                </p>
              </div>
              <div
                className={`w-fit px-8 py-2 rounded-2xl flex justify-center items-center ${
                  areChangesMade
                    ? "bg-black text-white cursor-pointer"
                    : "bg-gray-300 text-gray-500 cursor-not-allowed"
                }`}
              >
                <button type="submit">
                  <p className="font-semibold text-xs">Update</p>
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default EmployeeDashboard;
