import { useState, useEffect } from "react";
import axios from "axios";
import EmployeeDashboard from "./EmployeeDashboard";
import { useAuth0 } from "@auth0/auth0-react";
import TopUpPage from "./TopUpPage";
import { MutatorOptions } from "swr";
import useProfile from "../lib/hooks/useProfile.hook";

export interface ContactInfo {
  address1: string;
  address2: string;
  city: string;
  postcode: string;
  country: string;
  phone: string;
}

export interface TopUp {
  quantity: number;
  date: Date;
}

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  code: string;
  organizationName: string;
  remainingCredits: number;
  monthlyCredits: number;
  sendDay: number;
  childcareCode: string;
  contactInfo: ContactInfo;
  topUps: TopUp[];
}

export interface UserPayload {
  status: "ok" | "error";
  code: number;
  user: User;
}

// TODO: fetch transactions to check if monthly credits have been sent yet,
// display past date if credits not yet received

const EmployeeProfile = () => {
  const { profilePayload, profileError, profileIsLoading, profileMutate } =
    useProfile();
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState<User>();

  const [screenToDisplay, setScreenToDisplay] = useState<"default" | "topUp">(
    "default"
  );

  const [topUpValue, setTopUpValue] = useState<number>(0);
  const [topUpValueIndex, setTopUpValueIndex] = useState<number>(-1);
  const [customValue, setCustomValue] = useState<number>(0);

  const accessToken = sessionStorage.getItem("minderfinder-access-token");

  const MAX_CUSTOM_VALUE = 1000;
  const CREDIT_TOP_UP_CURRENCY = "gbp";
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleResetTopUpVariables = () => {
    setTopUpValue(0);
    setTopUpValueIndex(-1);
    setCustomValue(0);
  };

  const handleClickTopUpValue = (value: number | string, index: number) => {
    const num = Number(value);
    setTopUpValueIndex((prevIndex) => {
      if (prevIndex === index) {
        setTopUpValue(0);
        return -1;
      } else {
        setTopUpValue(num);
        return index;
      }
    });
  };

  const handleChangeCustomValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const cleanedValue = value.replaceAll(/[^0-9]/g, "");
    if (Number(cleanedValue) <= MAX_CUSTOM_VALUE) {
      setCustomValue(Number(cleanedValue));
      setTopUpValue(Number(cleanedValue));
    }
  };

  const handleSubmitTopUp = async () => {
    const response = await axios.post(
      `${REACT_APP_API_BASE_URL}/initiateTopUp`,
      {
        amount: customValue,
        currency: CREDIT_TOP_UP_CURRENCY,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    if (response.data.url) {
      sessionStorage.setItem("stripeCheckoutSessionId", response.data.id);
      window.location = response.data.url;
    }
  };

  const handleContactInfoUpdateFn = async (updatedData: UserPayload) => {
    try {
      const response = await axios.put(
        `${REACT_APP_API_BASE_URL}/updateContactInfo`,
        {
          updatedContactInfo: updatedData.user.contactInfo,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      if (response.data.status === "ok") {
      }
    } catch (err) {}
    return updatedData;
  };

  const handleSubmitUpdatedContactInfo = async (
    updatedContactInfo: ContactInfo
  ) => {
    if (!profilePayload) {
      return;
    }

    const updatedData: UserPayload = {
      ...profilePayload,
      user: { ...profilePayload.user, contactInfo: updatedContactInfo },
    };

    const options: MutatorOptions<UserPayload> = {
      populateCache: false,
      rollbackOnError: true,
      revalidate: true,
      optimisticData: updatedData,
    };

    profileMutate(handleContactInfoUpdateFn(updatedData), options);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    sessionStorage.removeItem("stripeCheckoutSessionId");
    return () => {
      handleResetTopUpVariables();
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <>
      {profilePayload?.user && screenToDisplay === "default" ? (
        <EmployeeDashboard
          onClickAddCredits={() => setScreenToDisplay("topUp")}
          onSubmitUpdatedContactInfo={handleSubmitUpdatedContactInfo}
        />
      ) : (
        <TopUpPage
          customValue={customValue}
          maxCustomValue={MAX_CUSTOM_VALUE}
          onChange={handleChangeCustomValue}
          onSubmit={topUpValue > 0 ? handleSubmitTopUp : () => {}}
          isSubmitEnabled={topUpValue > 0}
          onClickBack={() => setScreenToDisplay("default")}
        />
      )}
    </>
  );
};

export default EmployeeProfile;
