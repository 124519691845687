import { useState } from "react";
import { TransactionObject } from "./Activity";
import ExpandingList from "./ExpandingList";
import dayjs from "dayjs";

const Transaction = (props: TransactionObject) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const lineItemsObject = props.lineItems?.reduce(
    (accumulator, currentItem) => ({
      ...accumulator,
      [currentItem.recipient.code]: `${currentItem.credits} credits`,
    }),
    {}
  );

  return (
    <div className="w-full h-fit flex mb-4">
      <div className="w-3/12 h-full flex items-center">
        <p className="px-3 text-left">
          {dayjs(props.date).format("DD/MM/YYYY")}
        </p>
      </div>
      <div className="w-4/12 px-2 h-full flex flex-col justify-start items-start">
        <p className="text-left">{props.title}</p>
        <p className="text-sm text-gray-500 ml-4 text-left">
          {props.subtitle}{" "}
          {lineItemsObject && (
            <span
              className="font-semibold cursor-pointer select-none"
              onClick={() => setIsExpanded((prev) => !prev)}
            >
              {isExpanded ? <code>&ndash;</code> : "+"}
            </span>
          )}
        </p>
        {lineItemsObject && (
          <ExpandingList
            data={lineItemsObject}
            isExpanded={isExpanded}
            additionalStyles={{
              outerDiv: "ml-4 text-xs text-gray-500 w-[225px]",
              innerDiv: "border-b",
              leftCol: "w-3/5 font-mono",
            }}
          />
        )}
      </div>
      <div className="w-2/12 h-full flex items-center">
        <p className="text-left">{props.credits}</p>
      </div>
      <div className="w-3/12 px-2 h-full flex items-center">
        <p className="text-left">{props.flowDirection}</p>
      </div>
    </div>
  );
};

export default Transaction;
