import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import LoginButton from "../components/LoginButton";

const Login = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [user]);

  if (isLoading) {
    return <></>;
  }

  return (
    <div className="mt-16">
      <h1 className="text-xl mb-4">Log in to minderfinder</h1>
      <LoginButton />
    </div>
  );
};

export default Login;
