import EmployeeProfile from "./EmployeeProfile";
import OrganizationProfile from "./OrganizationProfile";
import useBasicData from "../lib/hooks/useBasicData.hook";

const Profile = () => {
  const { basicDataPayload } = useBasicData();

  const userType = basicDataPayload?.userType;

  return (
    <>
      {userType === "employee" && <EmployeeProfile />}
      {userType === "organization" && <OrganizationProfile />}
    </>
  );
};

export default Profile;
