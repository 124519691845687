import dayjs from "dayjs";
import { Child } from "./ManageChildren";
import Button from "./Button";

const NewChildForm = (
  props: Child & {
    onChangeFirstName: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeLastName: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeDob: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeSex: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeAdditionalInfo: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onCancel: () => void;
    onSubmit: (e: React.FormEvent) => void;
  }
) => {
  return (
    <div className="w-[400px] flex flex-col items-start text-left border border-black rounded-md mx-4 mb-8 p-2">
      <form
        className="w-full"
        id="new-child-form"
        name="new-child-form"
        onSubmit={props.onSubmit}
      >
        <div className="w-1/2 mb-2 flex flex-col">
          <label className="font-semibold">First name</label>
          <input
            type="text"
            className="border rounded-sm pl-2"
            value={props.firstName}
            onChange={props.onChangeFirstName}
            required={true}
          />
        </div>
        <div className="w-1/2 mb-2 flex flex-col">
          <label className="font-semibold">Last name</label>
          <input
            type="text"
            className="border rounded-sm pl-2"
            value={props.lastName}
            onChange={props.onChangeLastName}
            required={true}
          />
        </div>
        <div className="w-1/2 mb-2 flex flex-col">
          <label className="font-semibold">DOB</label>
          <input type="date" onChange={props.onChangeDob} required={true} />
        </div>
        <div className="w-1/2 mb-2 flex flex-col">
          <label className="font-semibold">Sex</label>
          <select required={true}>
            <option value={"female"}>Female</option>
            <option value={"male"}>Male</option>
          </select>
        </div>
        <div className="w-full mb-2 flex flex-col">
          <label className="font-semibold">Additional information</label>
          <textarea
            className="border rounded-sm pl-2"
            value={props.additionalInfo}
            onChange={props.onChangeAdditionalInfo}
          />
        </div>
        <div className="mt-4 mb-2 flex justify-center">
          <Button
            text="Cancel"
            onClick={() => {
              props.onCancel();
            }}
            isEnabled={true}
            additionalStyles={`mx-2 ${
              true ? "bg-red-400 hover:bg-red-500" : ""
            }`}
            isSubmitButton={false}
          />
          <Button
            text="Save"
            onClick={() => props.onSubmit}
            isEnabled={true}
            additionalStyles={`mx-2 ${
              true ? "bg-emerald-400 hover:bg-emerald-500" : ""
            }`}
          />
        </div>
      </form>
    </div>
  );
};

export default NewChildForm;
