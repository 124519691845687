const ExpandingList = (props: {
  data: Record<string | number, string | number>;
  isExpanded: boolean;
  additionalStyles?: Record<string, string>;
}) => {
  return (
    <div
      className={`w-full overflow-scroll ${
        props.isExpanded ? "max-h-60" : "max-h-0"
      } transition-all duration-500 ${props.additionalStyles?.outerDiv}`}
    >
      {Object.entries(props.data).map(([key, value], index) => (
        <div
          className={`w-full flex ${props.additionalStyles?.innerDiv}`}
          key={`expanding-list-item-${index}`}
        >
          <div className={`${props.additionalStyles?.leftCol}`}>
            <p className={`text-left ${props.additionalStyles?.p}`}>{key}</p>
          </div>
          <div className={`${props.additionalStyles?.rightCol}`}>
            <p className={`text-left ml-2 ${props.additionalStyles?.p}`}>
              {value}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExpandingList;
