import { useEffect, useState } from "react";
import LoginButton from "../components/LoginButton";
import Slider from "../components/Slider";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import checkIfUserExists from "../lib/checkIfUserExists";
import Button from "../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

type AccountType = "organization" | "employee";

const Register = () => {
  const [accessToken, setAccessToken] = useState<string>("");
  const [accountType, setAccountType] = useState<AccountType>("employee");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [organizationCode, setOrganizationCode] = useState<string>("");
  const [organizationName, setOrganizationName] = useState<string>("");
  const [address1, setAddress1] = useState<string>("");
  const [address2, setAddress2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [postcode, setPostcode] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } =
    useAuth0();

  const navigate = useNavigate();

  const fetchAccessToken = async () => {
    const token = await getAccessTokenSilently();
    setAccessToken(token);
  };

  const handleUserExistence = async () => {
    if (user && isAuthenticated && accessToken) {
      const userExistencePayload = await checkIfUserExists(accessToken);
      if (userExistencePayload.userType) {
        navigate("/");
      }
    }
  };

  useEffect(() => {
    handleUserExistence();
  }, [user]);

  useEffect(() => {
    fetchAccessToken();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    if (!(user && isAuthenticated && accessToken)) {
      return;
    }
    if (accountType === "employee") {
      const data = {
        firstName,
        lastName,
        organizationCode,
        email: user.email,
        address1,
        address2,
        city,
        postcode,
        country,
        phone,
      };
      const response = await axios.post(
        `${REACT_APP_API_BASE_URL}/users/create`,
        data,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      if (response.data.status === "ok") {
        navigate("/");
      } else {
        // TODO: do something
      }
    } else if (accountType === "organization") {
      const data = {
        organizationName,
        email: user.email,
        address1,
        address2,
        city,
        postcode,
        country,
        phone,
      };
      const response = await axios.post(
        `${REACT_APP_API_BASE_URL}/organizations/create`,
        data,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      if (response.data.status === "ok") {
        navigate("/");
      } else {
        // TODO: do something
      }
    }

    setIsSubmitting(false);
  };

  if (isLoading) {
    return (
      <div className="mt-16">
        <h1>Loading profile</h1>
        <FontAwesomeIcon icon={faSpinner} spin />
      </div>
    );
  }

  const contactInfoBlock = (
    <>
      <label className="mt-4" htmlFor="address1">
        Address Line 1
      </label>
      <input
        className="border rounded-sm pl-2"
        id="address1"
        value={address1}
        required={true}
        onChange={(e) =>
          setAddress1(e.target.value === " " ? "" : e.target.value)
        }
      ></input>
      <label className="mt-4" htmlFor="address2">
        Address Line 2
      </label>
      <input
        className="border rounded-sm pl-2"
        id="address2"
        value={address2}
        required={false}
        onChange={(e) =>
          setAddress2(e.target.value === " " ? "" : e.target.value)
        }
      ></input>
      <label className="mt-4" htmlFor="city">
        City
      </label>
      <input
        className="border rounded-sm pl-2"
        id="city"
        value={city}
        required={true}
        onChange={(e) => setCity(e.target.value === " " ? "" : e.target.value)}
      ></input>
      <label className="mt-4" htmlFor="postcode">
        Postcode
      </label>
      <input
        className="border rounded-sm pl-2"
        id="postcode"
        value={postcode}
        required={true}
        onChange={(e) =>
          setPostcode(e.target.value === " " ? "" : e.target.value)
        }
      ></input>
      <label className="mt-4" htmlFor="country">
        Country
      </label>
      <input
        className="border rounded-sm pl-2"
        id="country"
        value={country}
        required={true}
        onChange={(e) =>
          setCountry(e.target.value === " " ? "" : e.target.value)
        }
      ></input>
      <label className="mt-4" htmlFor="phone">
        Phone number
      </label>
      <input
        className="border rounded-sm pl-2"
        id="phone"
        value={phone}
        required={true}
        onChange={(e) => setPhone(e.target.value === " " ? "" : e.target.value)}
      ></input>
    </>
  );

  return (
    <div className="mt-16">
      <h1 className="text-xl">Set up your account</h1>
      <div className="flex justify-center">
        <form className="flex flex-col items-start" onSubmit={handleSubmit}>
          <label className="mt-4" htmlFor="account-type-dropdown">
            Account type
          </label>
          <select
            className="border rounded-sm pl-1"
            id="account-type-dropdown"
            onChange={(e) => setAccountType(e.target.value as AccountType)}
          >
            <option value="employee">Employee</option>
            <option value="organization">Organization</option>
          </select>
          {accountType === "employee" && (
            <>
              <label className="mt-4" htmlFor="first-name">
                First name
              </label>
              <input
                className="border rounded-sm pl-2"
                id="first-name"
                value={firstName}
                required={true}
                onChange={(e) =>
                  setFirstName(e.target.value === " " ? "" : e.target.value)
                }
              ></input>
              <label className="mt-4" htmlFor="last-name">
                Last name
              </label>
              <input
                className="border rounded-sm pl-2"
                id="last-name"
                value={lastName}
                required={true}
                onChange={(e) =>
                  setLastName(e.target.value === " " ? "" : e.target.value)
                }
              ></input>
              {contactInfoBlock}
              <label className="mt-4" htmlFor="organization-code">
                Organization code
              </label>
              <input
                className="border rounded-sm pl-2"
                id="organization-code"
                value={organizationCode}
                required={true}
                onChange={(e) => {
                  if (e.target.value !== " ") {
                    setOrganizationCode(
                      e.target.value === " " ? "" : e.target.value
                    );
                  }
                }}
              ></input>
            </>
          )}
          {accountType === "organization" && (
            <>
              <label className="mt-4" htmlFor="organization-name">
                Organization Name
              </label>
              <input
                className="border rounded-sm pl-2"
                id="organization-name"
                value={organizationName}
                required={true}
                onChange={(e) =>
                  setOrganizationName(
                    e.target.value === " " ? "" : e.target.value
                  )
                }
              ></input>
              {contactInfoBlock}
            </>
          )}
          <div className="w-full mt-4 flex flex-col items-center">
            <Button
              text="Finish"
              isEnabled={
                (accountType === "employee" &&
                  firstName !== "" &&
                  lastName !== "" &&
                  organizationCode !== "") ||
                (accountType === "organization" && organizationName !== "")
              }
              onClick={() => handleSubmit}
              additionalStyles="mb-4"
            />
            <Button
              text="Abort"
              isEnabled={true}
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
              additionalStyles="bg-red-400 hover:bg-red-500"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
