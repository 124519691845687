import { useAuth0 } from "@auth0/auth0-react";
import Button from "./Button";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  const handleClick = () => loginWithRedirect();

  return <Button text="Log in" onClick={handleClick} isEnabled={true} />;
};

export default LoginButton;
