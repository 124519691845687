const EmployeeListItem = (props: {
  id: string;
  firstName: string;
  lastName: string;
  code: string;
  buttonValue: number;
  monthlyCredits: number;
  onIncrementCredits: (userCode: string, value: number) => void;
  onChangeCredits: (
    e: React.ChangeEvent<HTMLInputElement>,
    userCode: string
  ) => void;
  edited: boolean;
}) => {
  const editedClassName =
    "before:content-[''] before:w-4 before:h-4 before:absolute before:bg-emerald-400 before:rounded-full before:left-3 before:mt-4 before:z-0";

  return (
    <div className="w-full h-16 bg-white flex border-b">
      <div className="w-1/2 ml-8 flex flex-col justify-center items-start">
        <h1 className={`${props.edited ? editedClassName : ""}`}>
          {props.firstName} <span className="font-bold">{props.lastName}</span>
        </h1>
        <p className="text-xs font-mono text-gray-500">{props.code}</p>
      </div>
      <div className="w-1/2 mr-4 flex justify-end items-center">
        <h1
          className="text-2xl mr-1 cursor-pointer select-none"
          onClick={() =>
            props.onIncrementCredits(
              props.id,
              props.monthlyCredits - props.buttonValue
            )
          }
        >
          -
        </h1>
        <input
          className="w-20 text-center text-3xl font-mono"
          type="text"
          inputMode="numeric"
          value={props.monthlyCredits}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            props.onChangeCredits(e, props.id)
          }
        />
        <h1
          className="text-xl ml-1 cursor-pointer select-none"
          onClick={() =>
            props.onIncrementCredits(
              props.id,
              props.monthlyCredits + props.buttonValue
            )
          }
        >
          +
        </h1>
      </div>
    </div>
  );
};

export default EmployeeListItem;
