import { TransactionObject } from "./Activity";
import Transaction from "./Transaction";

const TransactionsContainer = (props: {
  transactions: TransactionObject[];
}) => {
  return (
    <div className="w-full sm:w-5/6 h-fit mb-8 overflow-scroll">
      <div className="w-full h-16 flex pt-8 top-0 sticky bg-white">
        <div className="w-3/12 h-full flex items-center">
          <p className="px-3 text-sm font-semibold">Date</p>
        </div>
        <div className="w-4/12 px-2 h-full flex items-center">
          <p className="text-sm font-semibold">Name</p>
        </div>
        <div className="w-2/12 h-full flex items-center">
          <p className="text-sm font-semibold">Amount</p>
        </div>
        <div className="w-3/12 px-2 h-full flex items-center">
          <p className="text-sm font-semibold">Status</p>
        </div>
      </div>
      <div className="h-full w-full">
        {props.transactions.map((transaction, index) => (
          <Transaction
            date={transaction.date}
            title={transaction.title}
            credits={transaction.credits}
            flowDirection={transaction.flowDirection}
            key={`transaction-date-group-${index}`}
          />
        ))}
      </div>
    </div>
  );
};

export default TransactionsContainer;
