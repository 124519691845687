import useSWR from "swr";
import { ActivityPayload } from "../../components/Activity";
import fetchWithToken from "../fetcher";

const useTransactions = () => {
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const accessToken = sessionStorage.getItem("minderfinder-access-token");

  const {
    data: transactionsPayload,
    error: transactionsError,
    isLoading: transactionsIsLoading,
  } = useSWR<ActivityPayload>(
    [`${REACT_APP_API_BASE_URL}/activity`, accessToken],
    ([url, token]) => fetchWithToken(url, token as string)
    // { refreshInterval: 1000 }
  );

  return {
    transactionsPayload,
    transactionsError,
    transactionsIsLoading,
  };
};

export default useTransactions;
