import { NavLink } from "react-router-dom";

const defaultStyle = "w-3/4 text-left text-sm mb-6";
const activeStyle = "font-semibold text-blue-600";

const AccountNavBar = (props: {
  userType: "employee" | "organization";
  picture: string;
  isExpanded: boolean;
  onShow: () => void;
  onHide: () => void;
}) => {
  return (
    <>
      {!props.isExpanded && (
        <div
          className="h-8 w-8 sm:h-0 sm:w-0 z-50 absolute top-20 bg-gray-200 overflow-hidden cursor-pointer"
          onClick={props.onShow}
        >
          &gt;
        </div>
      )}
      <div
        className={`w-5/6 sm:w-[200px] ${
          props.isExpanded ? "" : "-translate-x-[100%]"
        } sm:translate-x-0 transition-all fixed z-10 h-screen pt-32 bg-gray-200 overflow-hidden`}
      >
        <div className="w-full relative cursor-pointer">
          {props.isExpanded && (
            <p className="absolute right-2 bottom-6" onClick={props.onHide}>
              X
            </p>
          )}
        </div>
        <div className="ml-8 flex flex-col">
          <img className="w-20 h-20 rounded-full mb-8" src={props.picture} />
          <NavLink
            to=""
            className={({ isActive }) =>
              `${defaultStyle} ${isActive ? activeStyle : ""}`
            }
            onClick={props.onHide}
          >
            Dashboard
          </NavLink>
          {props.userType === "employee" && (
            <NavLink
              to="children"
              className={({ isActive }) =>
                `${defaultStyle} ${isActive ? activeStyle : ""}`
              }
              onClick={props.onHide}
            >
              Manage Children
            </NavLink>
          )}
          {props.userType === "employee" && (
            <NavLink
              to="providers"
              className={({ isActive }) =>
                `${defaultStyle} ${isActive ? activeStyle : ""}`
              }
              onClick={props.onHide}
            >
              Childcare Providers
            </NavLink>
          )}
          {props.userType === "organization" && (
            <NavLink
              to="employees"
              className={({ isActive }) =>
                `${defaultStyle} ${isActive ? activeStyle : ""}`
              }
              onClick={props.onHide}
            >
              Manage Employees
            </NavLink>
          )}
          <NavLink
            to="activity"
            className={({ isActive }) =>
              `${defaultStyle} ${isActive ? activeStyle : ""}`
            }
            onClick={props.onHide}
          >
            Activity
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default AccountNavBar;
