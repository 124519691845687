const Button = (props: {
  text: string;
  onClick: () => void;
  isEnabled: boolean;
  additionalStyles?: string;
  isSubmitButton?: boolean;
}) => {
  const enabledClassName = "bg-blue-500 hover:bg-blue-700 text-white";
  const disabledClassName = "bg-gray-300 text-gray-500 cursor-not-allowed";

  const isSubmit =
    props.isSubmitButton === undefined ? true : props.isSubmitButton;

  if (isSubmit) {
    return (
      <button
        className={`w-fit font-bold py-2 px-4 rounded ${
          props.isEnabled ? enabledClassName : disabledClassName
        } ${props.additionalStyles}`}
        onClick={props.onClick}
      >
        {props.text}
      </button>
    );
  } else {
    return (
      <button
        type="button"
        className={`w-fit font-bold py-2 px-4 rounded ${
          props.isEnabled ? enabledClassName : disabledClassName
        } ${props.additionalStyles}`}
        onClick={props.onClick}
      >
        {props.text}
      </button>
    );
  }
};

export default Button;
