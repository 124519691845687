import axios from "axios";

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const checkIfUserExists = async (accessToken: string) => {
  const response = await axios.get(`${REACT_APP_API_BASE_URL}/userType`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data;
};

export default checkIfUserExists;
