import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Welcome from "./pages/Welcome";
import Success from "./pages/Success";
import Cancel from "./pages/Cancel";
import Activity from "./components/Activity";
import ManageEmployees from "./components/ManageEmployees";
import ChildcareProviders from "./components/ChildcareProviders";
import ProviderPage from "./components/ProviderPage";
import Profile from "./components/Profile";
import ManageChildren from "./components/ManageChildren";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route index element={<Profile />} />
            <Route path="children" element={<ManageChildren />} />
            <Route path="employees" element={<ManageEmployees />} />
            <Route path="providers" element={<ChildcareProviders />}>
              <Route path=":provider" element={<ProviderPage />} />
            </Route>
            <Route path="activity" element={<Activity />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
