import useSWR from "swr";
import { UserPayload } from "../../components/EmployeeProfile";
import fetchWithToken from "../fetcher";

const useProfile = () => {
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const accessToken = sessionStorage.getItem("minderfinder-access-token");

  const {
    data: profilePayload,
    error: profileError,
    isLoading: profileIsLoading,
    mutate: profileMutate,
  } = useSWR<UserPayload>(
    [`${REACT_APP_API_BASE_URL}/profile`, accessToken],
    ([url, token]) => fetchWithToken(url, token as string)
    // { refreshInterval: 1000 }
  );

  return {
    profilePayload,
    profileError,
    profileIsLoading,
    profileMutate,
  };
};

export default useProfile;
