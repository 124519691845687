import dayjs from "dayjs";
import { Child } from "./ManageChildren";
import Button from "./Button";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const ChildDetails = (
  props: Child & {
    index: number;
    onDelete: (index: number) => Promise<boolean>;
  }
) => {
  const [isDeleteOverlayVisible, setIsDeleteOverlayVisible] =
    useState<boolean>(false);
  return (
    <div className="w-[400px] relative flex flex-col items-start text-left border border-black rounded-md mx-4 mb-8 p-2">
      {isDeleteOverlayVisible && (
        <div className="w-full h-full absolute top-0 left-0 z-10 flex flex-col justify-center items-center bg-black/80 backdrop-blur-sm outline rounded-md">
          <h1 className="text-white text-3xl text-center font-bold">{`Delete data for child`}</h1>
          <h1 className="text-white text-3xl text-center font-bold">{`${props.firstName} ${props.lastName}?`}</h1>
          <h1 className="text-white text-2xl text-center font-bold">
            This cannot be undone
          </h1>
          <div className="mt-4">
            <Button
              text="Delete data"
              onClick={async () => {
                const isDeletionSuccessful = await props.onDelete(props.index);
                if (isDeletionSuccessful) {
                  setIsDeleteOverlayVisible(false);
                }
              }}
              isEnabled={true}
              additionalStyles={`mx-2 ${
                true ? "bg-red-400 hover:bg-red-500" : ""
              }`}
              isSubmitButton={false}
            />
            <Button
              text="Keep data"
              onClick={() => setIsDeleteOverlayVisible(false)}
              isEnabled={true}
              additionalStyles={`mx-2 ${
                true ? "bg-emerald-400 hover:bg-emerald-500" : ""
              }`}
            />
          </div>
        </div>
      )}
      <div>
        <FontAwesomeIcon
          icon={faCircleXmark}
          className="absolute right-2 text-xl text-gray-400 hover:text-red-500 active:text-red-700 cursor-pointer"
          onClick={() => setIsDeleteOverlayVisible(true)}
        />
      </div>
      <div className="mb-2">
        <p className="font-semibold">First name</p>
        <p>{props.firstName}</p>
      </div>
      <div className="mb-2">
        <p className="font-semibold">Last name</p>
        <p>{props.lastName}</p>
      </div>
      <div className="mb-2">
        <p className="font-semibold">DOB</p>
        <p>{dayjs(props.dob).format("DD/MM/YYYY")}</p>
      </div>
      <div className="mb-2">
        <p className="font-semibold">Sex</p>
        <p>{`${props.sex.slice(0, 1).toUpperCase()}${props.sex.slice(1)}`}</p>
      </div>
      <div className="mb-2">
        <p className="font-semibold">Additional information</p>
        <p>{props.additionalInfo ? props.additionalInfo : "None"}</p>
      </div>
    </div>
  );
};

export default ChildDetails;
