const TopUpPage = (props: {
  customValue: number;
  maxCustomValue: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  isSubmitEnabled: boolean;
  onClickBack: () => void;
}) => {
  return (
    <div className="pt-11 ml-8 text-left">
      <h1 className="text-3xl">Add credits</h1>
      <p className="my-3 text-xs cursor-pointer" onClick={props.onClickBack}>
        ← back
      </p>
      <div className="mt-8 mb-12">
        <label htmlFor="top-up-value" className="text-lg">
          Amount{" "}
          <span className="text-xs text-gray-500">{`(max £${props.maxCustomValue})`}</span>
        </label>
        <input
          id="top-up-value"
          className="block border rounded-md mt-2 mb-5 pl-2"
          type="text"
          inputMode="numeric"
          value={`£ ${props.customValue}`}
          onChange={props.onChange}
        ></input>
      </div>
      <div
        className={`w-fit px-8 py-2 rounded-2xl flex justify-center items-center ${
          props.isSubmitEnabled
            ? "bg-black text-white cursor-pointer"
            : "bg-gray-300 text-gray-500 cursor-not-allowed"
        }`}
        onClick={props.isSubmitEnabled ? props.onSubmit : () => {}}
      >
        <p className="font-semibold text-xs">Add credits</p>
      </div>
    </div>
  );
};

export default TopUpPage;
