import useSWR from "swr";
import { ChildrenPayload } from "../../components/ManageChildren";
import fetchWithToken from "../fetcher";

const useChildren = () => {
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const accessToken = sessionStorage.getItem("minderfinder-access-token");

  const {
    data: childrenPayload,
    error: childrenError,
    isLoading: childrenIsLoading,
    mutate: childrenMutate,
  } = useSWR<ChildrenPayload>(
    [`${REACT_APP_API_BASE_URL}/children`, accessToken],
    ([url, token]) => fetchWithToken(url, token as string),
    // { refreshInterval: 1000 }
  );

  return {
    childrenPayload,
    childrenError,
    childrenIsLoading,
    childrenMutate,
  };
};

export default useChildren;
