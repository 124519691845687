import axios, { AxiosResponse } from "axios";

interface FetcherProps<T> {
  url: string;
  accessToken: string;
}

const fetchWithToken = async <T>(url: string, token: string): Promise<T> => {
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data
};

export default fetchWithToken;
