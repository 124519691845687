import dayjs from "dayjs";

export const calculateAdjustedDay = (
  day: number,
  startDate?: string
): number => {
  const start = dayjs(startDate);
  return Math.min(day, start.daysInMonth());
};

const findNextDate = (
  day: number,
  returnFormat: string = "YYYY-MM-DD",
  startDate?: string
): string => {
  const start = dayjs(startDate);

  const adjustedDay = calculateAdjustedDay(day, startDate);

  if (adjustedDay >= start.date()) {
    return dayjs(`${start.year()}-${start.month() + 1}-${adjustedDay}`).format(
      returnFormat
    );
  } else {
    const startDatePlusMonth = start.add(1, "month");
    const nextMonthAdjustedDay = calculateAdjustedDay(
      day,
      startDatePlusMonth.format("YYYY-MM-DD")
    );
    const dateString = [
      startDatePlusMonth.year(),
      startDatePlusMonth.month() + 1,
      nextMonthAdjustedDay,
    ].join("-");
    return dayjs(dateString).format(returnFormat);
  }
};

export default findNextDate;
