import { useEffect, useState } from "react";
import axios from "axios";
import findNextDate from "../lib/findNextDate";
import { Employee } from "./ManageEmployees";
import ExpandingList from "./ExpandingList";
import Button from "./Button";
import { Organization, OrganizationPayload } from "./OrganizationProfile";
import { ContactInfo } from "./EmployeeProfile";
import { MutatorOptions } from "swr";
import useOrganization from "../lib/hooks/useOrganization.hook";

// TODO: fetch transactions to check if monthly credits have been sent yet,
// display past date if credits not yet received

const OrganizationDashboard = (props: { onClickAddCredits: () => void }) => {
  const {
    organizationPayload,
    organizationError,
    organizationIsLoading,
    organizationMutate,
  } = useOrganization();

  const [isEmployeeCreditsExpanded, setIsEmployeeCreditsExpanded] =
    useState<boolean>(false);

  const [isContactInfoEditable, setIsContactInfoEditable] =
    useState<boolean>(false);
  const [updatedContactInfo, setUpdatedContactInfo] = useState<ContactInfo>(
    organizationPayload !== undefined
      ? organizationPayload.organization.contactInfo
      : {
          address1: "",
          address2: "",
          city: "",
          postcode: "",
          country: "",
          phone: "",
        }
  );

  const accessToken = sessionStorage.getItem("minderfinder-access-token");

  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const totalMonthlyEmployeeCredits = organizationPayload
    ? organizationPayload.organization.employees.reduce(
        (total, current) => total + current.monthlyCredits,
        0
      )
    : 0;

  const groupedMonthlyEmployeeCredits = organizationPayload
    ? organizationPayload.organization.employees.reduce(
        (accumulator: Record<number, number>, current: Employee) =>
          current.monthlyCredits in accumulator
            ? {
                ...accumulator,
                [current.monthlyCredits]:
                  accumulator[current.monthlyCredits] + 1,
              }
            : { ...accumulator, [current.monthlyCredits]: 1 },
        {}
      )
    : {};

  const monthlyEmployeeCreditsObject = groupedMonthlyEmployeeCredits
    ? Object.fromEntries(
        Object.entries(groupedMonthlyEmployeeCredits).map(
          ([numberOfCredits, numberOfEmployees]) => [
            `£${numberOfCredits}`,
            `${
              numberOfEmployees > 1
                ? `${numberOfEmployees} employees`
                : "1 employee"
            }`,
          ]
        )
      )
    : {};

  const areChangesMade =
    organizationPayload !== undefined &&
    Object.keys(organizationPayload.organization.contactInfo).some(
      (key: any) => {
        return (
          organizationPayload.organization.contactInfo[
            key as keyof ContactInfo
          ] !== updatedContactInfo[key as keyof ContactInfo]
        );
      }
    );

  const handleChangeContactInfo = (key: string, value: string) => {
    const cleanedValue = value.slice(0, 1) === " " ? value.slice(1) : value;
    setUpdatedContactInfo((prev) => ({ ...prev, [key]: cleanedValue }));
  };

  const handleCancelUpdateContactInfo = () => {
    if (organizationPayload) {
      setUpdatedContactInfo(organizationPayload.organization.contactInfo);
    }
  };

  const handleSendToEmployeesUpdateFn = async (
    updatedData: OrganizationPayload
  ) => {
    if (organizationPayload) {
      try {
        const response = await axios.post(
          `${REACT_APP_API_BASE_URL}/sendCreditsToEmployees`,
          { organizationId: organizationPayload.organization.organizationId },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        if (response.data.status === "ok") {
        }
      } catch (err) {}
    }
    return updatedData;
  };

  const handleSendToEmployees = async () => {
    if (organizationPayload) {
      const updatedData = {
        ...organizationPayload,
        remainingCredits:
          organizationPayload.organization.remainingCredits -
          totalMonthlyEmployeeCredits,
      };

      const options: MutatorOptions<OrganizationPayload> = {
        populateCache: false,
        rollbackOnError: true,
        revalidate: true,
        optimisticData: updatedData,
      };

      organizationMutate(handleSendToEmployeesUpdateFn(updatedData), options);
    }
  };

  const handleContactInfoUpdateFn = async (
    updatedData: OrganizationPayload
  ) => {
    try {
      const response = await axios.put(
        `${REACT_APP_API_BASE_URL}/updateContactInfo`,
        {
          updatedContactInfo: updatedData.organization.contactInfo,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      if (response.data.status === "ok") {
      }
    } catch (err) {}
    return updatedData;
  };

  const handleSubmitUpdatedContactInfo = async () => {
    const updatedData: OrganizationPayload =
      organizationPayload && organizationPayload.status
        ? {
            ...organizationPayload,
            organization: {
              ...organizationPayload.organization,
              contactInfo: updatedContactInfo,
            },
          }
        : {
            status: "ok",
            code: 0,
            organization: {
              organizationId: "",
              name: "",
              email: "",
              code: "",
              organizationName: "",
              remainingCredits: 0,
              billingDay: 0,
              sendDay: 0,
              employees: [],
              contactInfo: {
                address1: "",
                address2: "",
                city: "",
                postcode: "",
                country: "",
                phone: "",
              },
            },
          };

    const options: MutatorOptions<OrganizationPayload> = {
      populateCache: false,
      rollbackOnError: true,
      revalidate: true,
      optimisticData: updatedData,
    };

    organizationMutate(handleContactInfoUpdateFn(updatedData), options);
  };

  useEffect(() => {
    setIsContactInfoEditable(areChangesMade);
  }, [organizationPayload?.organization]);

  return (
    <>
      {organizationPayload && (
        <div className="ml-8 pb-10 flex flex-col">
          <h1 className="pt-11 text-left text-3xl">Account</h1>
          <div className="my-4 px-4 py-3 w-fit border rounded-md">
            <div className="mb-3 flex justify-between">
              <p className="text-left text-lg font-semibold">Balance</p>
              <p
                className={`text-left text-lg font-semibold ${
                  organizationPayload.organization.remainingCredits <
                  (totalMonthlyEmployeeCredits as number)
                    ? "text-red-400"
                    : ""
                }`}
              >
                £{organizationPayload.organization.remainingCredits}
              </p>
            </div>
            <div className="mb-4 flex justify-between">
              <p className="text-sm">Total monthly contribution to employees</p>
              <div className="pl-8">
                <p className="text-right text-sm">
                  £{totalMonthlyEmployeeCredits}{" "}
                  {organizationPayload.organization.employees.length > 0 && (
                    <span
                      className="font-semibold cursor-pointer select-none"
                      onClick={() =>
                        setIsEmployeeCreditsExpanded((prev) => !prev)
                      }
                    >
                      {isEmployeeCreditsExpanded ? <code>&ndash;</code> : "+"}
                    </span>
                  )}
                </p>
                <ExpandingList
                  data={monthlyEmployeeCreditsObject}
                  isExpanded={isEmployeeCreditsExpanded}
                  additionalStyles={{
                    leftCol: "w-[50px]",
                    p: "text-xs text-gray-500",
                  }}
                />
              </div>
            </div>
            <p className="text-left text-xs mt-1">
              Next minderfinder billing date on{" "}
              {findNextDate(
                organizationPayload.organization.billingDay,
                "dddd, MMM D"
              )}
            </p>
            <p className="text-left text-xs mt-1">
              Next employee credit renewal on{" "}
              {findNextDate(
                organizationPayload.organization.sendDay,
                "dddd, MMM D"
              )}
            </p>
          </div>
          <div
            className="w-fit px-8 py-2 rounded-2xl bg-black text-white flex justify-center items-center cursor-pointer"
            onClick={props.onClickAddCredits}
          >
            <p className="font-semibold text-xs">Add credits</p>
          </div>
          <Button
            text="Send credits to employees"
            onClick={
              totalMonthlyEmployeeCredits &&
              organizationPayload.organization.remainingCredits >=
                totalMonthlyEmployeeCredits
                ? handleSendToEmployees
                : () => {}
            }
            isEnabled={
              totalMonthlyEmployeeCredits
                ? organizationPayload.organization.remainingCredits >=
                  totalMonthlyEmployeeCredits
                : false
            }
            additionalStyles="mt-4"
          />
          <h2 className="mt-20 text-left text-2xl">Organization</h2>
          <div className="w-[90%] mt-2 mb-4 flex flex-wrap justify-between">
            <div className="w-fit min-w-[200px] pr-4 mr-1 my-2">
              <p className="text-sm text-left text-gray-500">
                Organization name
              </p>
              <p className="text-sm text-left px-2 border rounded-sm">
                {organizationPayload.organization.name}
              </p>
            </div>
            <div className="w-fit min-w-[200px] pr-4 mr-1 my-2">
              <p className="text-sm text-left text-gray-500">
                Organization code
              </p>
              <p className="text-sm text-left px-2 border rounded-sm">
                {organizationPayload.organization.code}
              </p>
            </div>
            <div className="w-fit min-w-[200px] pr-4 mr-1 my-2">
              <p className="text-sm text-left text-gray-500">
                Number of employees
              </p>
              <p className="text-sm text-left px-2 border rounded-sm">
                {organizationPayload.organization.employees.length}
              </p>
            </div>
            <div className="w-fit min-w-[200px] pr-4 mr-1 my-2">
              <p className="text-sm text-left text-gray-500">E-mail</p>
              <p className="text-sm text-left px-2 border rounded-sm">
                {organizationPayload.organization.email}
              </p>
            </div>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmitUpdatedContactInfo();
            }}
          >
            <h3 className="mt-10 text-left text-lg">Contact Information</h3>
            <div className="w-[90%] mt-2 mb-4 flex flex-wrap">
              <div className="w-full min-w-[200px] pr-4 mr-1 my-2">
                <p className="text-sm text-left text-gray-500">
                  Address line 1
                </p>
                <input
                  className="w-full text-sm text-left px-2 border rounded-sm"
                  value={updatedContactInfo.address1}
                  required
                  disabled={!isContactInfoEditable}
                  onChange={(e) =>
                    handleChangeContactInfo("address1", e.target.value)
                  }
                />
              </div>
              <div className="w-full min-w-[200px] pr-4 mr-1 my-2">
                <p className="text-sm text-left text-gray-500">
                  Address line 2
                </p>
                <input
                  className="w-full text-sm text-left px-2 border rounded-sm"
                  value={updatedContactInfo.address2}
                  disabled={!isContactInfoEditable}
                  onChange={(e) =>
                    handleChangeContactInfo("address2", e.target.value)
                  }
                />
              </div>
              <div className="w-1/3 min-w-[200px] pr-4 mr-1 my-2">
                <p className="text-sm text-left text-gray-500">City</p>
                <input
                  className="w-full text-sm text-left px-2 border rounded-sm"
                  value={updatedContactInfo.city}
                  required
                  disabled={!isContactInfoEditable}
                  onChange={(e) =>
                    handleChangeContactInfo("city", e.target.value)
                  }
                />
              </div>
              <div className="w-1/3 min-w-[200px] pr-4 mr-1 my-2">
                <p className="text-sm text-left text-gray-500">
                  Postal code / Zip
                </p>
                <input
                  className="w-full text-sm text-left px-2 border rounded-sm"
                  value={updatedContactInfo.postcode}
                  required
                  disabled={!isContactInfoEditable}
                  onChange={(e) =>
                    handleChangeContactInfo("postcode", e.target.value)
                  }
                />
              </div>
              <div className="w-full">
                <div className="w-1/3 min-w-[200px] pr-4 mr-1 my-2">
                  <p className="text-sm text-left text-gray-500">Country</p>
                  <input
                    className="w-full text-sm text-left px-2 border rounded-sm"
                    value={updatedContactInfo.country}
                    required
                    disabled={!isContactInfoEditable}
                    onChange={(e) =>
                      handleChangeContactInfo("country", e.target.value)
                    }
                  />
                </div>
                <div className="w-1/3 min-w-[200px] pr-4 mr-1 my-4">
                  <p className="text-sm text-left text-gray-500">
                    Phone number
                  </p>
                  <input
                    className="w-full text-sm text-left px-2 border rounded-sm"
                    value={updatedContactInfo.phone}
                    required
                    disabled={!isContactInfoEditable}
                    onChange={(e) =>
                      handleChangeContactInfo("phone", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div
                className="w-[100px] px-8 py-2 mr-4 rounded-2xl bg-black text-white flex justify-center items-center cursor-pointer"
                onClick={
                  !isContactInfoEditable
                    ? () => setIsContactInfoEditable(true)
                    : () => {
                        handleCancelUpdateContactInfo();
                        setIsContactInfoEditable(false);
                      }
                }
              >
                <p className="font-semibold text-xs">
                  {!isContactInfoEditable ? "Edit" : "Cancel"}
                </p>
              </div>
              <div
                className={`w-fit px-8 py-2 rounded-2xl flex justify-center items-center ${
                  areChangesMade
                    ? "bg-black text-white cursor-pointer"
                    : "bg-gray-300 text-gray-500 cursor-not-allowed"
                }`}
              >
                <button type="submit">
                  <p className="font-semibold text-xs">Update</p>
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default OrganizationDashboard;
