import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Cancel = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 2000);
  }, []);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("stripeCheckoutSessionId");
    };
  }, []);

  return (
    <div>
      <h1>Payment Cancelled</h1>
      <h1>You have not been charged. Navigating back to your home page</h1>
    </div>
  );
};

export default Cancel;
