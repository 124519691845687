import useTransactions from "../lib/hooks/useTransactions.hook";
import TransactionsContainer from "./TransactionsContainer";

interface LineItem {
  recipient: {
    code: string;
    firstName: string;
    lastName: string;
  };
  credits: number;
}

export interface TransactionObject {
  credits: number;
  date: string;
  flowDirection: "incoming" | "outgoing";
  title: "User top up" | "Employer transfer" | "Provider payment";
  subtitle?: string;
  lineItems?: LineItem[];
}

export interface ActivityPayload {
  status: "ok" | "error";
  code: number;
  user: {
    code: string;
  };
  transactions: TransactionObject[];
}

export type ActivityContextType = {
  transactions: TransactionObject[];
};

const Activity = () => {
  const { transactionsPayload, transactionsError, transactionsIsLoading } =
    useTransactions();

  if (transactionsIsLoading) {
    return <></>;
  }

  return (
    <>
      {transactionsPayload?.transactions &&
      Object.keys(transactionsPayload?.transactions).length > 0 ? (
        <>
          <TransactionsContainer
            transactions={transactionsPayload?.transactions}
          />
        </>
      ) : (
        <div className="mt-4">
          <h1>No transactions found</h1>
        </div>
      )}
    </>
  );
};

export default Activity;
