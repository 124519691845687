import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import checkIfUserExists from "../lib/checkIfUserExists";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Welcome = () => {
  const navigate = useNavigate();

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const handleUserExistence = async () => {
    const accessToken = await getAccessTokenSilently();
    if (user?.sub && isAuthenticated) {
      const userExistencePayload = await checkIfUserExists(accessToken);
      if (userExistencePayload.userType) {
        navigate("/");
      } else {
        navigate("/register");
      }
    }
  };

  useEffect(() => {
    handleUserExistence();
  }, [user]);

  return (
    <div className="mt-16">
      <h1>Loading profile</h1>
      <FontAwesomeIcon icon={faSpinner} spin />
    </div>
  );
};

export default Welcome;
