import useSWR from "swr";
import { OrganizationPayload } from "../../components/OrganizationProfile";
import fetchWithToken from "../fetcher";

const useOrganization = () => {
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const accessToken = sessionStorage.getItem("minderfinder-access-token");

  const {
    data: organizationPayload,
    error: organizationError,
    isLoading: organizationIsLoading,
    mutate: organizationMutate,
  } = useSWR<OrganizationPayload>(
    [`${REACT_APP_API_BASE_URL}/orgProfile`, accessToken],
    ([url, token]) => fetchWithToken(url, token as string)
    // { refreshInterval: 1000 }
  );

  return {
    organizationPayload,
    organizationError,
    organizationIsLoading,
    organizationMutate,
  };
};

export default useOrganization;
