import { useState } from "react";
import { Navigate } from "react-router-dom";
import Button from "./Button";
import ChildDetails from "./ChildDetails";
import NewChildForm from "./NewChildForm";
import useChildren from "../lib/hooks/useChildren.hook";
import useBasicData from "../lib/hooks/useBasicData.hook";
import axios from "axios";
import { MutatorOptions } from "swr";

export interface Child {
  firstName: string;
  lastName: string;
  sex: string;
  dob: Date;
  additionalInfo?: string;
}

export interface ChildrenPayload {
  status: "ok" | "error";
  code: number;
  children?: { _child: Child }[];
}

const ManageChildren = () => {
  const { childrenPayload, childrenError, childrenIsLoading, childrenMutate } =
    useChildren();

  const { basicDataPayload } = useBasicData();

  const userType = basicDataPayload?.userType;

  const [isNewChildFormVisible, setIsNewChildVisible] =
    useState<boolean>(false);
  const [newChildFirstName, setNewChildFirstName] = useState<string>("");
  const [newChildLastName, setNewChildLastName] = useState<string>("");
  const [newChildDob, setNewChildDob] = useState<Date>(new Date());
  const [newChildSex, setNewChildSex] = useState<string>("Female");
  const [newChildAdditionalInfo, setNewChildAdditionalInfo] =
    useState<string>("");

  const accessToken = sessionStorage.getItem("minderfinder-access-token");

  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleChangeNewChildFirstName = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value !== " ") {
      setNewChildFirstName(e.target.value);
    }
  };

  const handleChangeNewChildLastName = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value !== " ") {
      setNewChildLastName(e.target.value);
    }
  };

  const handleChangeNewChildDob = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewChildDob(new Date(e.target.value));
  };

  const handleChangeNewChildSex = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== " ") {
      setNewChildSex(e.target.value);
    }
  };

  const handleChangeNewChildAdditionalInfo = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (e.target.value !== " ") {
      setNewChildAdditionalInfo(e.target.value);
    }
  };

  const handleResetNewChildVariables = () => {
    setNewChildFirstName("");
    setNewChildLastName("");
    setNewChildDob(new Date());
    setNewChildSex("Female");
    setNewChildAdditionalInfo("");
  };

  const handleCloseNewChild = () => {
    setIsNewChildVisible(false);
    handleResetNewChildVariables();
  };

  const handleAddChildUpdateFn = async (
    updatedData: ChildrenPayload,
    newChildData: Child
  ): Promise<ChildrenPayload> => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URL}/children/create`,
        newChildData,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      if (response.data.status === "ok") {
        handleResetNewChildVariables();
      } else {
        setIsNewChildVisible(true);
      }
    } catch (err) {
      setIsNewChildVisible(true);
    }
    return updatedData;
  };

  const handleSubmitNewChild = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsNewChildVisible(false);

    const newChild: Child = {
      firstName: newChildFirstName,
      lastName: newChildLastName,
      dob: newChildDob,
      sex: newChildSex,
      additionalInfo: newChildAdditionalInfo,
    };

    const updatedData: ChildrenPayload =
      !childrenPayload || !childrenPayload.children
        ? { status: "ok", code: 0, children: [{ _child: newChild }] }
        : {
            ...childrenPayload,
            children: [...childrenPayload.children, { _child: newChild }],
          };

    const options: MutatorOptions<ChildrenPayload> = {
      populateCache: false,
      rollbackOnError: true,
      revalidate: true,
      optimisticData: (childrenPayload: any) => ({
        ...childrenPayload,
        children: [...childrenPayload.children, { _child: newChild }],
      }),
    };

    childrenMutate(handleAddChildUpdateFn(updatedData, newChild), options);
  };

  const handleDeleteChildUpdateFn = async (
    updatedData: ChildrenPayload,
    index: number
  ): Promise<ChildrenPayload> => {
    try {
      const response = await axios.delete(
        `${REACT_APP_API_BASE_URL}/children/${index}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      if (response.data.status === "ok") {
        handleCloseNewChild(); // for appearance only - closes the new child form after existing child is deleted, to look neater
      } else {
        childrenMutate();
      }
    } catch (err) {
      childrenMutate();
    }
    return updatedData;
  };

  const handleDeleteChildData = async (index: number) => {
    const updatedData: ChildrenPayload =
      !childrenPayload || !childrenPayload.children
        ? {
            status: "ok",
            code: 0,
            children: [],
          }
        : {
            ...childrenPayload,
            children: [
              ...childrenPayload.children.filter((child, i) => i !== index),
            ],
          };

    const options: MutatorOptions<ChildrenPayload> = {
      populateCache: false,
      rollbackOnError: true,
      revalidate: true,
      optimisticData: (childrenPayload: any) => ({
        ...childrenPayload,
        children: [
          ...childrenPayload.children.filter(
            (child: Child, i: number) => i !== index
          ),
        ],
      }),
    };

    childrenMutate(handleDeleteChildUpdateFn(updatedData, index), options);

    return false;
  };

  if (userType === "organization") {
    return <Navigate to="/" />;
  }

  if (childrenIsLoading) {
    return <></>;
  }

  return (
    <>
      <div className="pt-12 flex flex-wrap justify-start">
        {childrenPayload?.children?.map((c, index) => {
          const child = c._child;
          return (
            <ChildDetails
              firstName={child.firstName}
              lastName={child.lastName}
              sex={child.sex}
              dob={child.dob}
              additionalInfo={child.additionalInfo}
              index={index}
              onDelete={handleDeleteChildData}
              key={`child-details-${index}`}
            />
          );
        })}
        {isNewChildFormVisible && (
          <NewChildForm
            firstName={newChildFirstName}
            lastName={newChildLastName}
            dob={newChildDob}
            sex={newChildSex}
            additionalInfo={newChildAdditionalInfo}
            onChangeFirstName={handleChangeNewChildFirstName}
            onChangeLastName={handleChangeNewChildLastName}
            onChangeDob={handleChangeNewChildDob}
            onChangeSex={handleChangeNewChildSex}
            onChangeAdditionalInfo={handleChangeNewChildAdditionalInfo}
            onCancel={handleCloseNewChild}
            onSubmit={handleSubmitNewChild}
          />
        )}
      </div>
      <div className="flex justify-center mb-4">
        <Button
          text="Add child"
          onClick={() => setIsNewChildVisible(true)}
          isEnabled={true}
          additionalStyles="h-fit"
        />
      </div>
    </>
  );
};

export default ManageChildren;
